import React from "react";

import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";

import ModalVideo from "react-modal-video";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Title from "../components/title";
import About from "../components/about";
import Social from "../components/social";
import Logo from "../components/logo";

import { IconContext } from "react-icons";
import { GrCaretDown } from "react-icons/gr";

import { GrPlay } from "react-icons/gr";

dayjs.extend(RelativeTime);

function getDiff() {
  const diff = dayjs("2021-02-28 16:00").diff(dayjs(), "second");
  const seconds = diff % 60;
  const minutes = Math.floor(diff / 60) % 60;
  const hours = Math.floor(diff / (60 * 60)) % 24;
  const days = Math.floor(diff / (60 * 60 * 24));
  return `${days}:${hours < 10 ? "0" : ""}${hours}:${
    minutes < 10 ? "0" : ""
  }${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
}

const IndexPage = () => {
  const [time, setTime] = React.useState(getDiff());

  const [isOpen, setOpen] = React.useState(false);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setTime(getDiff());
    }, 1000);
    return () => clearTimeout(timer);
  }, [time]);

  return (
    <Layout>
      <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexFlow: "column nowrap",
          alignItems: "center",
          justifyContent: "flex-end",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <SEO title="Calli Soma" />

        <ModalVideo
          channel="youtube"
          autoplay
          youtube={{ modestbranding: 1, rel: 0, showinfo: 0, autoplay: 1 }}
          isOpen={isOpen}
          videoId="5ejC3pV6tSc"
          onClose={() => setOpen(false)}
        />

        <button
          className="btn"
          style={{
            position: "absolute",
            top: "50vh",
            transform: "translateY(-50%)",
          }}
          onClick={() => {
            setOpen(true);
          }}
        >
          <IconContext.Provider value={{ size: "3rem", color: "white" }}>
            <GrPlay className="white-gr" style={{ marginTop: "-0.5em" }} />
          </IconContext.Provider>
          <span
            style={{
              marginLeft: "0.2em",
              fontSize: "4rem",
              fontWeight: "300",
              color: "white",
            }}
          >
            Play
          </span>
        </button>

        <Title></Title>
        <a href="#about">
          <IconContext.Provider value={{ size: "2rem" }}>
            <GrCaretDown
              className="white-gr"
              style={{ stroke: "white", marginBottom: "2rem" }}
            />
          </IconContext.Provider>
        </a>
      </div>
      <a name="about" />
      {(process.env.NODE_ENV === "development" ||
        dayjs("2021-02-28 16:00").isBefore(dayjs())) && (
        <div className="content-container">
          <About title="Calli Soma">
            <p>
              Calli Soma est une <b>auteure-compositrice-interprète</b>{" "}
              méditerranéenne.
            </p>
            <p>
              Passionnée par la musique des mots, la chanteuse s’amuse dans son
              premier EP à naviguer entre{" "}
              <b>chanson française et poésie chantée.</b>
            </p>
            <p>L’Italie, la Grèce, l’Espagne, la France…</p>
            <p>
              Calli Soma transpose toutes les couleurs de sa <b>Méditerranée</b>{" "}
              dans un univers musical singulier.
            </p>
          </About>
          <About title="La Marée">
            <p>
              Amoureuse de la mer, c’est sur un voilier que Calli Soma vous
              embarque pour son premier titre <b>La&nbsp;Marée</b>.
            </p>
            <p
              style={{
                fontSize: "1.1em",
                fontWeight: 200,
                textAlign: "center",
                marginTop: "5rem",
              }}
            >
              Une traversée des sens aux allures de chants des sirènes à
              découvrir bientôt !
            </p>
          </About>
          <About title="Contact" line={true}>
            <div
              style={{
                display: "flex",
                flexFlow: "column nowrap",
                justifyContent: "center",
                alignItems: "center",
                color: "white",
                textAlign: "center",
              }}
            >
              <p>
                Retrouvez
                <br />
                <Logo />
                <br />
                sur les réseaux
              </p>
              <Social />
            </div>
          </About>
        </div>
      )}
    </Layout>
  );
};

export default IndexPage;
