import React from "react"

import "./logo.css";

const Logo = () => {
  return (
    <span className="title">
      <span className="upper">C</span>alli <span className="upper">S</span>oma
    </span>
  )
}

export default Logo
