import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

import Logo from "./logo";

const Title = ({ siteTitle }) => (
  <div
    style={{
      margin: `0 auto 32px`,
    }}
  >
    <h1 style={{ margin: 0, borderTop: "solid 2px white", textAlign: "center" }}>
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
        className="title large"
      >
        <Logo/>
      </Link>
    </h1>
    <div
      style={{
        display: "flex",
        flexFlow: "row nowrap",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <div
        style={{
          flexGrow: 1,
          borderTop: "solid 2px white"
        }}
      ></div>
      <h2
        style={{
          flexGrow:0,
          flexShrink: 1,
          color: `white`,
          margin: "0 16px",
          padding: 0
        }}
        className="subtitle"
      >
        La Marée
      </h2>
      <div
        style={{
          flexGrow: 1,
          borderTop: "solid 2px white"
        }}
      ></div>
    </div>
  </div>
)

Title.propTypes = {
  siteTitle: PropTypes.string,
}

Title.defaultProps = {
  siteTitle: ``,
}

export default Title
