import React from "react"
import { Link } from "gatsby"
import { FaFacebookSquare, FaInstagramSquare } from 'react-icons/fa';

import "./social.css"

const Social = () => {
  return (
    <div className="social-links">
      <Link to="https://www.instagram.com/callisoma">
        <FaInstagramSquare />
      </Link>
      <Link to="https://www.facebook.com/Calli-Soma-104823681648637">
        <FaFacebookSquare />
      </Link>
    </div>
  )
}

export default Social
